





































































































































































































































































import { computed, ref, inject } from '@vue/composition-api';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import AccessPolicyNode from './AccessPolicyNode.vue';
import AccessPolicyEdit from './AccessPolicyEdit.vue';
import { useAccessControl } from '../composable';
import { GeneralPolicy, ExceptionPolicy } from '../models';

export default {
    name: 'AccessPolicy',
    model: {
        prop: 'accessControl',
        event: 'update-access-control',
    },
    props: {
        identifier: {
            type: Number,
            required: true,
        },
        policies: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        organisationId: {
            type: Number,
            default: null,
        },
    },
    components: {
        AccessPolicyNode,
        Draggable,
        AccessPolicyEdit,
    },
    setup(props: any, { emit }: { emit: any }) {
        const error = ref(null);
        const showAddPolicy = ref(false);
        const policyInEditMode = ref<number | null>(null);
        const generalPolicy = ref<GeneralPolicy | null>(null);
        const processedPolicies = ref<ExceptionPolicy[]>([]);
        const pendingChangePolicy = ref(false);
        const isFeatureEnabled: any = inject('isEnabled');

        useAccessControl(props.policies, isFeatureEnabled)
            .initialize()
            .then((result: { generalPolicy: GeneralPolicy | null; policies: ExceptionPolicy[] }) => {
                if (result.generalPolicy === null) {
                    // Default general policy to be DENY ALL
                    generalPolicy.value = GeneralPolicy.DENY_ALL;
                } else {
                    generalPolicy.value = result.generalPolicy;
                }
                processedPolicies.value = result.policies;

                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            })
            .catch((e) => {
                error.value = e;
                throw e;
            });

        const defaultPolicyEffect = computed(() => generalPolicy.value && !generalPolicy.value.allow);

        const policyMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex - 1, processedPolicies.value);
            } else if (oldIndex > newIndex) {
                processedPolicies.value = R.move(oldIndex, newIndex + 1, processedPolicies.value);
            }
        };

        const editPolicyException = (policyToEditIndex: number) => {
            policyInEditMode.value = policyToEditIndex;
        };

        const deletePolicyException = (policyToDeleteIndex: number) => {
            processedPolicies.value.splice(policyToDeleteIndex, 1);
        };

        const addPolicy = (policy: ExceptionPolicy) => {
            processedPolicies.value.push(policy);
            showAddPolicy.value = false;
        };

        const changeGeneralPolicy = (policy: GeneralPolicy) => {
            if (pendingChangePolicy.value) {
                pendingChangePolicy.value = false;
                generalPolicy.value = policy;
                for (let i = 0; i < processedPolicies.value.length; i++) {
                    processedPolicies.value[i].allow = !policy.allow;
                }

                emit('update-access-control', {
                    generalPolicy: generalPolicy.value,
                    policies: processedPolicies.value,
                });
            } else {
                pendingChangePolicy.value = true;
                setTimeout(() => {
                    pendingChangePolicy.value = false;
                }, 10000);
            }
        };

        return {
            error,
            generalPolicy,
            processedPolicies,
            defaultPolicyEffect,
            showAddPolicy,
            policyInEditMode,
            GeneralPolicy,
            policyMoved,
            addPolicy,
            editPolicyException,
            deletePolicyException,
            changeGeneralPolicy,
            pendingChangePolicy,
        };
    },
};
